/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap-v5'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { KTSVG } from '../../_metronic/helpers';

class DataTable extends Component {
	constructor(props) {
		super(props)

		this.state = {
			entities: {
				data: [],
				current_page: 1,
				from: 1,
				last_page: 1,
				per_page: 10,
				to: 1,
				total: 1,
			},
			first_page: 1,
			current_page: 1,
			sorted_column: this.props.columns[0].value,
			offset: 4,
			order: 'DESC',
			/*new obj*/
			limit: '10',
			// offset: 0,
			globalSearch: '',
			columns: this.props.filters,
			additionalPostData: this.props.additionalPostData !== undefined ? this.props.additionalPostData : null
		}
	}

	componentDidUpdate(prevProps) {
		if ((this.props.newData !== undefined && (this.props.newData.id !== prevProps.newData.id)) || (this.props.additionalPostData !== undefined && (this.props.additionalPostData !== prevProps.additionalPostData))) {
			this.setState({ current_page: this.state.entities.current_page, loading: true, additionalPostData: this.props.additionalPostData !== undefined ? this.props.additionalPostData : null }, () => {
				this.fetchEntities()
			})
		}
	}

	fetchEntities() {
		this.setState({ loading: true })
		let fetchUrl = `${this.props.url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&globalSearch=${this.state.globalSearch}&per_page=${this.state.entities.per_page}`
		const requestOptions = postRequestOptions(this.state);

		fetch(fetchUrl, requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((response) => {
				this.setState({ entities: response.data.data, loading: false })
			})
			.catch((error) => {
				this.setState({ loading: false })
				console.log(error, 'catch the hoop')
			})
	}

	changePage(pageNumber) {
		this.setState({ current_page: pageNumber }, () => {
			this.fetchEntities()
		})
	}

	changePerPage = (evt) => {
		this.setState({ entities: { ...this.state.entities, per_page: evt.target.value } }, () => {
			this.fetchEntities()
		})
	}

	changeGlobalSearch = (evt) => {
		this.setState({
			globalSearch: evt.target.value
		},
			() => {
				this.fetchEntities();
				if (this.props.onValueChange) {
					this.props.onValueChange(this.state);
				}
			}
		)
	}

	columnsFilterChange = (evt) => {
		this.setState(
			{
				columns: {
					...this.state.columns,
					[evt.target.name]: {
						filterType: 'like',
						filterValue: evt.target.value,
					},
				},
			},
			() => {
				this.fetchEntities();
				if (this.props.onValueChange) {
					this.props.onValueChange(this.state);
				}
			}
		)
	}

	columnHead(value) {
		return value.split('_').join(' ')
	}

	pagesNumbers() {
		if (!this.state.entities.to) {
			return []
		}
		let from = this.state.entities.current_page - this.state.offset
		if (from < 1) {
			from = 1
		}
		let to = from + this.state.offset * 2
		if (to >= this.state.entities.last_page) {
			to = this.state.entities.last_page
		}
		let pagesArray = []

		for (let page = from; page <= to; page++) {
			pagesArray.push(page)
		}
		return pagesArray
	}

	componentDidMount() {
		this.setState({ current_page: this.state.entities.current_page, loading: true }, () => {
			this.fetchEntities()
		})
	}

	tableHeads() {
		let icon
		if (this.state.order === 'asc') {
			icon = <BiSortUp className="float-right" size={20}></BiSortUp>
		} else {
			icon = <BiSortDown className="float-right" size={20}></BiSortDown>
		}
		return this.props.columns.map((column, index) => {
			if (column.value === 'action') {
				return (
					<th style={column.style} key={index}>
						<p style={{ margin: 0 }}> {column.title}</p>
					</th>
				)
			} else {
				return (
					<th
						style={column.style}
						key={index}
						onClick={() => this.sortByColumn(column.value)}
					>
						<p style={{ margin: 0 }}>
							{' '}
							{this.columnHead(column.title)}
							{column.value === this.state.sorted_column && icon}
						</p>
					</th>
				)
			}
		})
	}

	tableHeadFilter() {
		return this.props.columns.map((column, index) => {
			if (column.value === 'action') {
				return <td key={index}></td>
			} else {
				return (
					<td key={index}>
						<input
							type='text'
							name={column.value}
							value={this.state.columns[column.value].filterValue}
							onChange={this.columnsFilterChange}
							className='form-control form-control-sm'
							style={{ borderColor: '#e4e6ef' }}
						/>
					</td>
				)
			}
		})
	}

	dataList() {
		if (this.state.entities?.data?.length) {
			return this.state.entities.data.map((value, key) => {
				return (
					<tr key={key}>
						{this.props.columns.map((column, index) => {
							// if (column.value !== 'action') {
							// 	return (
							// 		<td style={column.style} key={index}>
							// 			{value[column.value]}
							// 		</td>
							// 	)
							// } else {
							// 	return (
							// 		<td style={column.style} key={value[this.props.columns[0].value] + index}>
							// 			{column.actionsComponent(value)}
							// 		</td>
							// 	)
							// }
							if (column.hasActionsComponent !== undefined && column.hasActionsComponent === true) {
								return (
									<td style={column.style} key={value[this.props.columns[0].value] + index}>
										{column.actionsComponent(value)}
									</td>
								)
							}
							else if (column.hasHtmlComponent !== undefined && column.hasHtmlComponent === true) {
								return (
									<td style={column.style} key={value[this.props.columns[0].value] + index}>
										{column.htmlComponent(value)}
									</td>
								)
							}
							else {
								return (
									<td style={column.style} key={index}>
										{value[column.value]}
									</td>
								)
							}
						})}
					</tr>
				)
			})
		} else {
			return (
				<tr>
					<td colSpan={this.props.columns.length} className='text-center'>
						No Records Found.
					</td>
				</tr>
			)
		}
	}

	sortByColumn(column) {
		const { sorted_column, order, first_page } = this.state;

		// Determine new sorting order
		let newOrder = order;
		if (column === sorted_column) {
			newOrder = order === 'asc' ? 'desc' : 'asc';
		} else {
			newOrder = 'asc';
		}

		// Update state and fetch entities
		this.setState(
			{
				sorted_column: column,
				order: newOrder,
				current_page: column === sorted_column ? this.state.current_page : first_page
			},
			() => {
				this.fetchEntities();
				if (this.props.onValueChange) {
					this.props.onValueChange(this.state);
				}
			}
		);


		// if (column === this.state.sorted_column) {
		// 	this.state.order === 'asc'
		// 		? this.setState({ order: 'desc', current_page: this.state.first_page }, () => {
		// 			this.fetchEntities()
		// 		})
		// 		: this.setState({ order: 'asc' }, () => {
		// 			this.fetchEntities()
		// 		})
		// } else {
		// 	this.setState(
		// 		{ sorted_column: column, order: 'asc', current_page: this.state.first_page },
		// 		() => {
		// 			this.fetchEntities()
		// 		}
		// 	)
		// }
	}

	pageList() {
		return this.pagesNumbers().map((page) => {
			return (
				<li
					className={page === this.state.entities.current_page ? 'page-item active' : 'page-item'}
					key={page}
				>
					<button className='page-link' onClick={() => this.changePage(page)}>
						{page}
					</button>
				</li>
			)
		})
	}

	exportData = () => {
		let currentData = [];
		let headersTitle = [];

		this.props.columns.map((column) => {
			if (!column.hasActionsComponent) {
				headersTitle.push(column.title);
			}
		});
		currentData.push(headersTitle);
		this.state.entities.data.map((value) => {
			var rows = [];
			this.props.columns.map((column, index) => {
				if (!column.hasActionsComponent) {
					rows.push(value[column.value]);
				}
			});
			currentData.push(rows);
		})
		this.exportToCsv('My Data.csv', currentData);
	}

	exportToCsv = (filename, rows) => {
		var processRow = function (row) {
			var finalVal = '';
			for (var j = 0; j < row.length; j++) {
				var innerValue = row[j] === null || row[j] === undefined ? '' : row[j].toString();
				if (row[j] instanceof Date) {
					innerValue = row[j].toLocaleString();
				};
				var result = innerValue.replace(/"/g, '""');
				if (result.search(/("|,|\n)/g) >= 0)
					result = '"' + result + '"';
				if (j > 0)
					finalVal += ',';
				finalVal += result;
			}
			return finalVal + '\n';
		};

		var csvFile = '';
		for (var i = 0; i < rows.length; i++) {
			csvFile += processRow(rows[i]);
		}

		var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
		var link = document.createElement("a");
		if (link.download !== undefined) {
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	render() {
		return (
			<>
				<div className='row'>
					<div className='col-md-2'>
						<select
							className='form-control form-control-sm'
							value={this.state.entities.per_page}
							onChange={this.changePerPage}
							style={{ borderColor: '#e4e6ef' }}
						>
							<option value='10'>10</option>
							<option value='100'>100</option>
							<option value='200'>200</option>
							<option value='500'>500</option>
						</select>
					</div>
					<div className='col-md-5'>
						{this.state.entities.data && this.state.entities.data.length > 0 && (
							<>

								<nav>
									<ul className='pagination'>
										<li className='page-item'>
											<button
												className='page-link'
												disabled={1 === this.state.entities.current_page}
												onClick={() => this.changePage(this.state.entities.current_page - 1)}
											>
												Previous
											</button>
										</li>
										{this.pageList()}
										<li className='page-item'>
											<button
												className='page-link'
												disabled={this.state.entities.last_page === this.state.entities.current_page}
												onClick={() => this.changePage(this.state.entities.current_page + 1)}
											>
												Next
											</button>
										</li>
										<span style={{ marginTop: '8px' }}>
											{' '}
											&nbsp;{' '}
											<i>
												Displaying {this.state.entities.data.length} of {this.state.entities.total}{' '}
												entries.
											</i>
										</span>
									</ul>
								</nav>
							</>
						)}
					</div>
					<div className='col-md-3'>
						<input
							type='text'
							placeholder='Global Search'
							value={this.state.globalSearch}
							onChange={this.changeGlobalSearch}
							className='form-control form-control-sm'
							style={{ borderColor: '#e4e6ef' }}
						/>
					</div>
					<div className='col-md-2 me-0'>
						<button className='btn btn-sm btn-secondary me-0' onClick={this.exportData} disabled={this.state.entities?.data?.length === 0}>
							<KTSVG path='/media/svg/icons/Files/Download.svg' className='svg-icon-1 svg-icon-3 me-0' />
						</button>
					</div>
				</div>
				<br />
				<div className='table-responsive'>
					<table className='table table-sm table-hover  table-striped table-bordered'>
						<thead>
							<tr>{this.tableHeads()}</tr>
							<tr>{this.tableHeadFilter()}</tr>
						</thead>
						<tbody>
							{this.state.loading ? (
								<tr>
									<td colSpan={this.props.columns.length} className='text-center'>
										{' '}
										<Spinner animation='grow' />
										<Spinner animation='grow' />
										<Spinner animation='grow' />
									</td>
								</tr>
							) : (
								this.dataList()
							)}
						</tbody>
					</table>
				</div>

			</>
		)
	}
}

export default DataTable