/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Select from 'react-select'
import { Table } from 'react-bootstrap-v5'
import { useHistory, useParams } from "react-router-dom"
import { KTSVG } from '../../_metronic/helpers'

interface RouteParams {
	id: string;
}

interface InitialState {
	idProducts: number;
	productName: string;
	itemCode: string;
	alternateName: string;
	size: number;
	packing: string;
	unitName: string;
	itemUnitName: string;
	itemCost: number;
	overheadCost: number;
	vat: number;
	weightedLoss: number;
	totalCost: number;
}

interface Overhead {
	idProductCostOverheads: number | null;
	idOverheads: number | null;
	overheadName: string;
	amount: number;
	[key: string]: any;
}

interface Item {
	idProductCostItems: number | null;
	idItems: number | null;
	itemName: string;
	categoryName: string;
	quantity: number;
	rate: number;
	amount: number;
	[key: string]: any;
}


function ProductionCostSetting() {

	let history = useHistory();
	const { id }: RouteParams = useParams();
	const [productionItems, setProductionItems] = useState([]);
	const [overheads, setOverheads] = useState([]);
	const initialState: InitialState = {
		idProducts: 0,
		productName: '',
		alternateName: '',
		itemCode: '',
		size: 0,
		unitName: '',
		packing: '',
		itemUnitName: '',
		itemCost: 0,
		overheadCost: 0,
		vat: 0,
		weightedLoss: 0,
		totalCost: 0
	};

	const [productOverheads, setProductOverheads] = useState<Overhead[]>([{
		idProductCostOverheads: null,
		idOverheads: null,
		overheadName: '',
		amount: 0,
	}]);

	const [productItems, setProductItems] = useState<Item[]>([{
		idProductCostItems: null,
		idItems: null,
		itemName: '',
		categoryName: '',
		quantity: 0,
		rate: 0,
		amount: 0,
	}]);

	const [item, setItem] = useState(initialState);

	useEffect(() => {
		getProductInfo(id)
		getAllProductionItems()
		getAllOverheads()
	}, [])

	const getProductInfo = (idProducts: string) => {
		fetch(
			process.env.REACT_APP_API_URL + 'products/' + idProducts,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				const modifiedItem = {
					...resp.data,
					itemCost: parseFloat(resp.data.itemCost),
					overheadCost: parseFloat(resp.data.overheadCost),
					weightedLoss: parseFloat(resp.data.weightedLoss),
					vat: parseFloat(resp.data.vat),
					totalCost: parseFloat(resp.data.totalCost),
				};
				setItem(modifiedItem);
				const transformedItems = resp.items.map((item: any) => {
					return {
						...item,
						rate: parseFloat(item.rate),
						quantity: parseFloat(item.quantity),
						amount: parseFloat(item.amount),
					};
				});
				setProductItems(transformedItems);
				const transformedOverheads = resp.overheads.map((overhead: any) => {
					return {
						...overhead,
						amount: parseFloat(overhead.amount),
					};
				});
				setProductOverheads(transformedOverheads);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getAllProductionItems = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'allProductionItems',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let items = resp.map((item: any) => {
					item.label = item.item_name;
					item.categoryName = item.category_name;
					item.value = item.id_items;
					// item.rate = item.rate;
					return item
				})
				setProductionItems(items);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getAllOverheads = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'allOverheads',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let overheads = resp.map((item: any) => {
					item.label = item.overhead_name;
					item.value = item.id_overheads;
					return item
				})
				setOverheads(overheads);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}


	// const [isSubmitDisable, setSubmitDisable] = useState(false);
	// const [loading, setLoading] = useState(false);

	const saveProductionCostSetting = async (event: any) => {
		event.preventDefault()
		// setSubmitDisable(true);
		// setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to save this setting?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Save it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions({ product: item, items: productItems, overheads: productOverheads })
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}product-cost-settings/${id}`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				// setSubmitDisable(false);
				// setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				// setSubmitDisable(false);
			}
		}
	}

	const addOverhead = () => {
		const newOverhead: Overhead = {
			idProductCostOverheads: null,
			idOverheads: null,
			overheadName: '',
			rate: 0,
			amount: 0,
		};

		const updatedOverheads = [...productOverheads];
		updatedOverheads.push(newOverhead);
		setProductOverheads(updatedOverheads);
	}

	const addProduct = () => {
		const newItem: Item = {
			idProductCostItems: null,
			idItems: null,
			itemName: '',
			categoryName: '',
			quantity: 0,
			rate: 0,
			amount: 0,
		};

		const updatedItems = [...productItems];
		updatedItems.push(newItem);
		setProductItems(updatedItems);
	}

	const handleOverheadChange = (data: any, index: number) => {
		const updatedOverheads = [...productOverheads];
		updatedOverheads[index].idOverheads = data.value;
		setProductOverheads(updatedOverheads);
	}

	const handleItemChange = (data: any, index: number) => {
		console.log(data)
		const updatedItems = [...productItems];
		updatedItems[index].idItems = data.value;
		updatedItems[index].categoryName = data.categoryName;
		updatedItems[index].unitName = data.unit_name;
		updatedItems[index].rate = data.rate;
		updatedItems[index].amount = data.rate * updatedItems[index].quantity;
		setProductItems(updatedItems);
		calculateItemCost()
	}

	const handleItemDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const updatedItems = [...productItems];
		updatedItems[index][e.target.name] = e.target.value;
		updatedItems[index].amount = parseFloat((updatedItems[index].rate * updatedItems[index].quantity).toFixed(2));
		setProductItems(updatedItems);
		calculateItemCost()
	}

	const handleOverheadDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const updatedOverheads = [...productOverheads];
		updatedOverheads[index][e.target.name] = parseFloat(e.target.value) || 0;
		setProductOverheads(updatedOverheads);
		calculateOverheadCost()
	}

	const deleteOverhead = (index: number) => {
		const updatedOverheads = [...productOverheads];
		if (updatedOverheads && updatedOverheads[index]) {
			updatedOverheads.splice(index, 1);
			setProductOverheads(updatedOverheads);
		}
	}

	const deleteItem = (index: number) => {
		const updatedItems = [...productItems];
		if (updatedItems && updatedItems[index]) {
			updatedItems.splice(index, 1);
			setProductItems(updatedItems);
		}
	}

	const calculateItemCost = () => {
		let totalAmount = 0;
		for (const row of productItems) {
			totalAmount += row.amount;
		}
		setItem({ ...item, itemCost: parseFloat(totalAmount.toFixed(2)) })
	};
	const calculateOverheadCost = () => {
		let totalAmount = 0;
		for (const row of productOverheads) {
			totalAmount += row.amount;
		}
		setItem({ ...item, overheadCost: parseFloat(totalAmount.toFixed(2)) })
	};

	const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setItem({ ...item, [event.target.name]: parseFloat(event.target.value) });
	}

	return (

		<div className='card card-custom'>
			<div className='card-header'>
				<div className="card-title">
					<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
					<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
						Production Cost Setting
					</span>
				</div>
			</div>

			<div className='card-body'>
				<form onSubmit={saveProductionCostSetting}>
					<Table size='sm' bordered hover>
						<tr>
							<td width={'30%'}>Product Name </td>
							<td width={'70%'}> : {item.productName}</td>
						</tr>
						<tr>
							<td>Alternate Name </td>
							<td> : {item.alternateName}</td>
						</tr>
						<tr>
							<td>Product Code </td>
							<td> : {item.itemCode}</td>
						</tr>
						<tr>
							<td>Size </td>
							<td> : {item.size + ' ' + item.itemUnitName}</td>
						</tr>
						<tr>
							<td>Pack </td>
							<td> : {item.packing + ' ' + item.itemUnitName}</td>
						</tr>
						<tr>
							<td>Unit </td>
							<td> : {item.unitName}</td>
						</tr>
						<tr>
							<td colSpan={2} className='text-end'>
								<Button variant='success' size="sm" onClick={() => addProduct()}><i className='fa fa-plus'></i> Add Item</Button>
								&nbsp;
								<Button variant='success' size="sm" onClick={() => addOverhead()}><i className='fa fa-plus'></i> Add Overhead</Button>
							</td>
						</tr>
					</Table>

					<Table size='sm' bordered hover>
						<thead>
							<tr>
								<th className="col-3">Item</th>
								<th className="col-2">Category</th>
								<th className="col-2">Quantity</th>
								<th className="col-1">Unit</th>
								<th className="col-2">Rate</th>
								<th className="col-2">Amount</th>
								<th className="col-1">Action</th>
							</tr>
						</thead>
						<tbody>
							{productItems.map((productItem, index) => (
								<tr key={'item' + index}>
									<td className='text-left'>
										<Select
											// value={product.idItems}
											// defaultValue={['label': 'ddd', 'value': 0]} 
											placeholder={productItem.itemName}
											onChange={(selectedOption) => handleItemChange(selectedOption, index)}
											options={productionItems}
											className='sm'
											menuPosition="fixed"
											styles={{
												control: (provided, state) => ({
													...provided,
													height: '32px',
													minHeight: '32px',
												}),
												container: (provided, state) => ({
													...provided,
													height: '32px',
												}),
											}}
										/>
									</td>
									<td>{productItem.categoryName}</td>
									<td>
										<input type="number" name="quantity" value={productItem.quantity} onChange={(event) => handleItemDataChange(event, index)} className="form-control form-control-sm" />
									</td>
									<td>{productItem.unitName}</td>
									<td>
										<input type="number" name="rate" value={productItem.rate} onChange={(event) => handleItemDataChange(event, index)} className="form-control form-control-sm" />
									</td>
									<td>
										<input type="number" name="amount" value={productItem.amount} readOnly className="form-control form-control-sm" />
									</td>
									<td>
										<button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteItem(index)}>
											<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
										</button>
									</td>
								</tr>
							))}
							<tr>
								<td className='text-end' colSpan={5}><b>Total Item Cost : </b></td>
								<td><b>{item.itemCost}</b></td>
							</tr>
						</tbody>
					</Table>

					<Table size='sm' bordered hover>
						<thead>
							<tr>
								<th className="col-9">Ohverhead Name</th>
								<th className="col-2">Amount</th>
								<th className="col-1">Action</th>
							</tr>
						</thead>
						<tbody>
							{productOverheads.map((productOverhead, index) => (
								<tr key={'overhead' + index}>
									<td className='text-left'>
										<Select
											// value={product.idItems}
											// defaultValue={['label': 'ddd', 'value': 0]} 
											placeholder={productOverhead.overheadName}
											onChange={(selectedOption) => handleOverheadChange(selectedOption, index)}
											options={overheads}
											className='sm'
											menuPosition="fixed"
											styles={{
												control: (provided, state) => ({
													...provided,
													height: '32px',
													minHeight: '32px',
												}),
												container: (provided, state) => ({
													...provided,
													height: '32px',
												}),
											}}
										/>

									</td>
									<td>
										<input type="number" name="amount" value={productOverhead.amount} onChange={(event) => handleOverheadDataChange(event, index)} className="form-control form-control-sm" />
									</td>
									<td>
										<button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteOverhead(index)}>
											<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
										</button>
									</td>
								</tr>
							))}
							<tr>
								<td className='text-end'><b>Total Overhead Cost : </b></td>
								<td><b>{item.overheadCost}</b></td>
							</tr>
						</tbody>
					</Table>

					<Table size='sm' bordered hover>

						<tbody>
							{/* <tr>
								<td className='text-end col-9'><b>Weighted Loss : </b></td>
								<td className='col-2'>
									<input type='number' name="weightedLoss" value={item.weightedLoss} onChange={handleInputOnChange} className="form-control form-control-sm" />
								</td>
								<td className='col-1'>&nbsp;</td>
							</tr> */}
							<tr>
								<td className='text-end col-9'><b>Total Production Cost (Item + Overhead) : </b></td>
								<td className='col-3'><b>{(item.itemCost + item.overheadCost).toFixed(2)}</b></td>
							</tr>
							<tr>
								<td className='text-end col-9'><b>VAT : </b></td>
								<td className='col-3'>
									<input type='number' name="vat" value={item.vat} onChange={handleInputOnChange} className="form-control form-control-sm" />
								</td>
							</tr>
							<tr>
								<td className='text-end col-9'><b>Total Production Cost with VAT : </b></td>
								<td className='col-3'><b>{(item.itemCost + item.overheadCost + item.vat).toFixed(2)}</b></td>
							</tr>
						</tbody>


					</Table>

					{/* <pre>{JSON.stringify(item, null, 2)}</pre> */}

					<div className="form-group row">
						<div className="col text-center">
							<a target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_BACKEND}productCostExport/${item.idProducts}?type=export`} className="btn btn-sm btn-primary me-2"><span>  Export</span></a>
							<button type="submit" className="btn btn-sm btn-primary me-2"><span>  Save</span></button>
							<Button variant='secondary' size="sm" onClick={() => history.goBack()}>
								Close
							</Button>
						</div>
					</div>
				</form>
			</div>


		</div>

	)
}

export default ProductionCostSetting
