/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory } from "react-router-dom";


interface OrderRows {
    idProducts: number;
    idProductGroupAlternateNames: number;
    quantity: number;
    rate: number;
    discount: number;
    originalAmount: number;
    amount: number;
    originalRate: number;
    delivery_location: string;
    effective_rate: number;
    transportation_cost: number;
    remarks: string;
    [key: string]: any; // Index signature
}

interface OrderTerms {
    clause: string;
    [key: string]: any;
}

interface Product {
    id_products: number;
    product_name: string;
}

interface OrderData {
    idSalesQuotations: number | null;
    idProjects: number;
    idLeads: number;
    idCompanies: number;
    idSr: number;
    purchaseOrderNo: string;
    requisitionNo: string;
    salesType: string;
    remarks: string;
    date: string;
    creditDays: number;
    creditDaysFrom: string;
    billingAddress: string;
    shippingAddress: string;
}

interface LeadAmount {
    previousOrder: string;
    invoice: number;
    payment: number;
    shipment: number;
}

const initialLeadAmount: LeadAmount = {
    previousOrder: '',
    invoice: 0,
    payment: 0,
    shipment: 0
}

const SalesOrderCreate = () => {

    const numberFormat = (value: number) => {

        // console.log('aaaaaaaa' + value, isNaN(value))
        // if (value === undefined || !isNaN(value)) {
        if (value === undefined) {
            return value;
        }

        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    let history = useHistory();

    const orderData: OrderData = {
        idSalesQuotations: null,
        idProjects: 0,
        idLeads: 0,
        idSr: 0,
        idCompanies: 0,
        date: '',
        creditDays: 0,
        creditDaysFrom: 'delivery',
        billingAddress: '',
        shippingAddress: '',
        purchaseOrderNo: '',
        requisitionNo: '',
        salesType: '',
        remarks: '',
    };

    const [comapnySelectAbility, setComapnySelectAbility] = useState(false);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState({ label: 'Select Client', value: '' });
    const [srs, setSrs] = useState([]);
    const [sr, setSr] = useState({ label: 'Select SR', value: '' });
    const [quotations, setQuotations] = useState([]);
    const [quotation, setQuotation] = useState({ label: 'Select Quotation', value: '' });
    const [leads, setLeads] = useState([]);
    const [order, setOrder] = useState(orderData);
    const [lead, setLead] = useState({ label: 'Select Lead', value: '' });
    const [products, setProducts] = useState<Product[]>([]);
    const [leadAmount, setLeadAmount] = useState<LeadAmount>(initialLeadAmount);

    useEffect(() => {
        getProjects();
        getCompanies();
        getQuotations();
    }, [])

    useEffect(() => {

        if (lead.value !== '' && order.idLeads !== 0) {
            fetch(
                process.env.REACT_APP_API_URL + 'sales-order-by-lead/' + lead.value,
                getRequestOptions()
            )
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {

                    let companyTemp: any = { label: resp.lead.company.company_name, value: resp.lead.id_companies, id: resp.lead.id_companies, name: resp.lead.company.company_name };
                    setCompany(companyTemp)
                    let srTemp: any = { label: resp.lead.assigned_to.first_name + ' ' + resp.lead.assigned_to.last_name, value: resp.lead.assigned_to.id, id: resp.lead.assigned_to.id, name: resp.lead.assigned_to.first_name + ' ' + resp.lead.assigned_to.last_name };
                    setSr(srTemp)
                    setLeadAmount({ ...leadAmount, previousOrder: resp.total.previousOrder, invoice: parseFloat(resp.total.invoice), payment: parseFloat(resp.total.payment), shipment: parseFloat(resp.total.shipment) });
                    setComapnySelectAbility(true)
                    setOrder({
                        ...order,
                        idCompanies: resp.lead.id_companies,
                        idSr: resp.lead.assigned_to.id,
                        billingAddress: (resp.lead.company.company_address === null) ? '' : resp.lead.company.company_address,
                        shippingAddress: (resp.lead.company.company_address === null) ? '' : resp.lead.company.company_address,
                    });

                    getCompanyCreditInfo(resp.lead.id_companies)

                })
                .catch((error) => {
                    console.log(error, 'catch the hoop')
                })


        } else {
            setComapnySelectAbility(false)
        }

    }, [lead])

    useEffect(() => {

        setOrder({
            ...order,
            billingAddress: company.address === null ? '' : company.address,
            shippingAddress: company.address === null ? '' : company.address,
        })

        // handleCompanyChange(company)

    }, [company])

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getCompanies = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/companies',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let companiesObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    item.defaultCreditLimit = parseInt(item.defaultCreditLimit);
                    item.maximumCreditLimit = parseInt(item.maximumCreditLimit);
                    return item
                })
                setCompanies(companiesObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjectSrs = (idProjects: number) => {
        fetch(
            process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let srsObj = resp.map((item: any) => {
                    item.label = item.first_name + ' ' + item.last_name;
                    item.value = item.id;
                    return item
                })
                setSrs(srsObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getQuotations = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'quotation',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let quotationObj = resp.data.map((item: any) => {
                    item.label = item.referenceNo;
                    item.value = item.id;
                    return item
                })
                setQuotations(quotationObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjectLeads = (idProjects: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'getLeadsByProject/' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let leadObj = resp.data.map((item: any) => {
                    item.label = item.lead_name;
                    item.value = item.id_leads;
                    return item
                })

                var allLeadsOpt = [{ id: 0, name: '', label: '--No Lead--', value: 0 }]
                let allLeads = allLeadsOpt.concat(leadObj);
                setLeads(allLeads);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleQuotationChange = (quotation: any) => {

        setQuotation(quotation);

        fetch(
            process.env.REACT_APP_API_URL + 'quotation/' + quotation.value,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let projectTemp: any = { label: resp.quotation.project.project_name, value: resp.quotation.id_projects, id: resp.quotation.id_projects, name: resp.quotation.project.project_name };
                let leadTemp: any = { label: resp.quotation.lead.lead_name, value: resp.quotation.id_leads, id: resp.quotation.id_leads, name: resp.quotation.lead.lead_name };
                setProject(projectTemp)
                setOrder({ ...order, idProjects: resp.quotation.id_projects, idCompanies: resp.quotation.lead.id_companies, idLeads: resp.quotation.id_leads, idSalesQuotations: quotation.value });
                setLead(leadTemp)
                getProducts(resp.quotation.id_projects);

                let orderTerms = [...resp.quotation.terms];
                setOrderTerms(orderTerms);

                let orderRows = [...resp.quotation.rows];

                orderRows = orderRows.map((row, index) => ({
                    ...row,
                    idProducts: parseFloat(row.id_products),
                    idProductGroupAlternateNames: row.id_product_group_alternate_names,
                    quantity: parseFloat(row.quantity) || 0,
                    rate: parseFloat(row.unit_rate),
                    originalRate: parseFloat(row.original_rate),
                    transportation_cost: parseFloat(row.transportation_cost) || 0,
                    effective_rate: parseFloat(row.unit_rate) + (parseFloat(row.transportation_cost) || 0),
                    discount: parseFloat(row.discount_percent) || 0,
                    srDiscount: parseFloat(row.discount_percent_sr) || 0,
                    adminDiscount: parseFloat(row.discount_percent_admin) || 0,
                    maxDiscount: parseFloat(row.max_discount) || 0,
                    originalAmount: (parseFloat(row.quantity) || 0) * parseFloat(row.unit_rate),
                    amount: ((parseFloat(row.quantity) || 0) * parseFloat(row.unit_rate)) - (parseFloat(row.quantity) * parseFloat(row.unit_rate) * parseFloat(row.discount_percent) / 100),
                    productName: (row.id_product_group_alternate_names === null ? row.product.product_name : row.alternate_name.alternate_name) + ' (' + row.product.packing + ' ' + row.product.item.unit.unit_name + ')',
                    delivery_location: row.delivery_location
                }));

                setOrderRows(orderRows);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })


    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        getProjectLeads(project.value);
        getProjectSrs(project.value);
        setOrder({ ...order, idProjects: project.value, idLeads: 0, idSr: 0 });
        setLead({ label: 'Select Lead', value: '' })
        setSr({ label: 'Select SR', value: '' })
        getProducts(project.value);
        setOrderRows([{
            idProducts: 0,
            idProductGroupAlternateNames: 0,
            quantity: 0,
            rate: 0,
            delivery_location: '',
            remarks: '',
            effective_rate: 0,
            transportation_cost: 0,
            discount: 0,
            originalAmount: 0,
            amount: 0,
            originalRate: 0,
        }]);
    }

    const handleLeadChange = (lead: any) => {
        setLead(lead);
        setOrder({ ...order, idLeads: lead.value });
    }

    const handleCompanyChange = (company: any) => {

        setCompany(company);
        setOrder({
            ...order,
            idCompanies: company.value,
            billingAddress: company.address === null ? '' : company.address,
            shippingAddress: company.address === null ? '' : company.address,
        });

        getCompanyCreditInfo(company.value)

    }

    const getCompanyCreditInfo = (idCompanies: any) => {
        fetch(
            process.env.REACT_APP_API_URL + 'company-credit-info/' + idCompanies,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                setCompany({
                    ...company, label: resp.data.companyName, name: resp.data.companyName, value: resp.data.idCompanies
                    , dueAmount: resp.data.dueAmount, availableCredit: resp.data.availableCredit
                    , maximumCreditLimit: resp.data.maximumCreditLimit, defaultCreditLimit: resp.data.defaultCreditLimit
                    , transactionPossible: resp.data.transactionPossible, dueColor: resp.data.dueColor
                    , address: resp.data.address
                });

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleSrChange = (sr: any) => {
        setSr(sr);
        setOrder({ ...order, idSr: sr.value });
    }

    const handleItemChange = (data: any, index: number) => {

        const updatedOrderRows = [...orderRows];
        updatedOrderRows[index].idProducts = data.value.split('-')[0];
        updatedOrderRows[index].idProductGroupAlternateNames = data.value.split('-')[1] === "null" ? null : data.value.split('-')[1];
        updatedOrderRows[index].originalRate = parseFloat(data.sale_per_unit);
        updatedOrderRows[index].rate = parseFloat(data.sale_per_unit);
        updatedOrderRows[index].effective_rate = parseFloat(data.sale_per_unit);
        updatedOrderRows[index].srDiscount = parseFloat(data.discount_percent_sr);
        updatedOrderRows[index].adminDiscount = parseFloat(data.discount_percent_admin);
        updatedOrderRows[index].maxDiscount = parseFloat(data.max_discount);
        setOrderRows(updatedOrderRows);
    }

    const getProducts = (project: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'products?idProjects=' + project,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let products = resp.data.map((item: any) => {
                    item.label = item.name_value + ' (' + item.packing + ' ' + item.item_unit_name + ')';
                    item.value = item.id_products + '-' + item.id_product_group_alternate_names;
                    item.unitRate = item.sale_per_unit;
                    return item
                })
                setProducts(products);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    // const [isSubmitDisable, setSubmitDisable] = useState(false);
    // const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedOrderRows = [...orderRows];
        updatedOrderRows[index][e.target.name] = (e.target.type === 'number') ? parseFloat(e.target.value) : e.target.value;
        if (updatedOrderRows[index].discount > parseFloat(updatedOrderRows[index].maxDiscount)) {
            updatedOrderRows[index].discount = 0;
        }
        // updatedOrderRows[index].rate = updatedOrderRows[index].rate - (updatedOrderRows[index].discount * updatedOrderRows[index].rate / 100);
        updatedOrderRows[index].effective_rate = updatedOrderRows[index].rate + updatedOrderRows[index].transportation_cost;
        updatedOrderRows[index].originalAmount = parseFloat((updatedOrderRows[index].quantity * (updatedOrderRows[index].rate + updatedOrderRows[index].transportation_cost)).toFixed(2))
        updatedOrderRows[index].amount = parseFloat((updatedOrderRows[index].quantity * (updatedOrderRows[index].rate + updatedOrderRows[index].transportation_cost - (updatedOrderRows[index].discount * updatedOrderRows[index].rate / 100))).toFixed(2))
        setOrderRows(updatedOrderRows);
    }

    const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedOrderTerms = [...orderTerms];
        updatedOrderTerms[index][e.target.name] = e.target.value;
        setOrderTerms(updatedOrderTerms);
    }

    const deleteProduct = (index: number) => {
        const updatedOrderRows = [...orderRows];
        if (updatedOrderRows && updatedOrderRows[index]) {
            updatedOrderRows.splice(index, 1);
            setOrderRows(updatedOrderRows);
        }
    }

    const deleteTerm = (index: number) => {
        const updatedOrderTerms = [...orderTerms];
        if (updatedOrderTerms && updatedOrderTerms[index]) {
            updatedOrderTerms.splice(index, 1);
            setOrderTerms(updatedOrderTerms);
        }
    }

    const orderSave = async (event: any) => {
        event.preventDefault()
        // setSubmitDisable(true);
        // setLoading(true);
        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this Order?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Create it!',
        })
        if (confirm.value) {
            const postData = {
                order: order,
                orderRows: orderRows,
                orderTerms: orderTerms,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}sales-order`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    // const successMsg = respData.successMessage
                    // Swal.fire({
                    //     icon: 'success',
                    //     title: 'Success',
                    //     text: successMsg,
                    // })

                    history.push("/sales-orders");
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
                // setSubmitDisable(false);
                // setLoading(false);
            } catch (error) {
                console.log(error, 'catch the hoop')
                // setSubmitDisable(false);
                // setLoading(false);
            }
        }
    }

    const addProduct = (index: number) => {
        const newRow: OrderRows = {
            idProducts: 0,
            quantity: 0,
            rate: 0,
            discount: 0,
            maxDiscount: 0,
            srDiscount: 0,
            adminDiscount: 0,
            originalAmount: 0,
            amount: 0,
            originalRate: 0,
            delivery_location: '',
            transportation_cost: 0,
            effective_rate: 0,
            remarks: '',
        };

        const updatedRows = [...orderRows];
        updatedRows.push(newRow);

        setOrderRows(updatedRows);
    }

    const addTerm = (index: number) => {
        const newTerm: OrderTerms = {
            clause: '',
        };

        const updatedTerms = [...orderTerms];
        updatedTerms.push(newTerm);
        setOrderTerms(updatedTerms);
    }

    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrder({ ...order, [event.target.name]: event.target.value });
    }

    const handleSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setOrder({ ...order, [event.target.id]: event.target.value });
    };

    const calculateTotalAmount = () => {
        let totalAmount = 0;

        for (const row of orderRows) {
            totalAmount += (row.originalAmount);
        }

        return totalAmount;
    };

    const calculateTotalDiscountAmount = () => {
        let totalDiscountAmount = 0;

        for (const row of orderRows) {
            totalDiscountAmount += ((row.originalAmount * row.discount) / 100);
        }

        return totalDiscountAmount;
    };


    const [orderRows, setOrderRows] = useState<OrderRows[]>([{
        idProducts: 0,
        idProductGroupAlternateNames: 0,
        quantity: 0,
        rate: 0,
        discount: 0,
        srDiscount: 0,
        adminDiscount: 0,
        maxDiscount: 0,
        originalAmount: 0,
        amount: 0,
        originalRate: 0,
        transportation_cost: 0,
        effective_rate: 0,
        delivery_location: '',
        remarks: '',
    }]);

    const [orderTerms, setOrderTerms] = useState<OrderTerms[]>([{
        clause: '',
    }]);

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Create Sales Order
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">

                    <form onSubmit={orderSave}>

                        {/* <pre>{JSON.stringify(orderRows, null, 2)}</pre> */}
                        <div className="form-group row">
                            <label htmlFor="idSalesQuotation" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Quotation</label>
                            <div className="col-lg-5">
                                <Select
                                    value={quotation}
                                    onChange={handleQuotationChange}
                                    options={quotations}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Lead </label>
                            <div className="col-lg-5">
                                <Select
                                    value={lead}
                                    onChange={handleLeadChange}
                                    options={leads}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        {order.idLeads !== 0 &&
                            <div className="form-group row">
                                <label htmlFor="companyName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}></label>
                                <div className="col-lg-5">
                                    Previous Order Amount : {(leadAmount.previousOrder === 'N/A') ? leadAmount.previousOrder : numberFormat(parseFloat(leadAmount.previousOrder))};
                                    Total Shipment Amount : {numberFormat(leadAmount.shipment)};
                                    Total Invoice Amount : {numberFormat(leadAmount.invoice)};
                                    Total Payment Made: {numberFormat(leadAmount.payment)}</div>
                            </div>
                        }
                        <div className="form-group row">
                            <label htmlFor="idCompanies" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Client <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={company}
                                    onChange={handleCompanyChange}
                                    options={companies}
                                    isDisabled={comapnySelectAbility}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idSr" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select SR </label>
                            <div className="col-lg-5">
                                <Select
                                    value={sr}
                                    onChange={handleSrChange}
                                    options={srs}
                                    isDisabled={comapnySelectAbility}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="date" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Date  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="date" id="date" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="billingAddress" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Billing Address <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input name="billingAddress" id="billingAddress" value={order.billingAddress} onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="shippingAddress" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Shipping Address <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input name="shippingAddress" id="shippingAddress" value={order.shippingAddress} onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="creditDays" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Credit Days <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='number' name="creditDays" id="creditDays" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="creditDaysFrom" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Credit Days From <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <select name="creditDaysFrom" id="creditDaysFrom" onChange={handleSelectOnChange} className="form-control form-control-sm">
                                    <option value={'delivery'}>Delivery</option>
                                    <option value={'invoice'}>Invoice</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="purchaseOrderNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purchase Order No</label>
                            <div className="col-lg-5">
                                <input name="purchaseOrderNo" id="purchaseOrderNo" value={order.purchaseOrderNo} onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="requisitionNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Requisition No</label>
                            <div className="col-lg-5">
                                <input name="requisitionNo" id="requisitionNo" value={order.requisitionNo} onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="salesType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Sales Type</label>
                            <div className="col-lg-5">
                                <label>
                                    <input
                                        name="salesType"
                                        type="radio"
                                        value="Inter Business Unit"
                                        onChange={handleInputOnChange}
                                    />
                                    &nbsp;Inter Business Unit
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;

                                <label>
                                    <input
                                        name="salesType"
                                        type="radio"
                                        value="Corporate"
                                        onChange={handleInputOnChange}
                                    />
                                    &nbsp;Corporate
                                </label>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks</label>
                            <div className="col-lg-5">
                                <input name="remarks" id="remarks" value={order.remarks} onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <br></br>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '15%' }} className='text-center'>Product Name <span className='text-danger'>*</span> </th>
                                    <th style={{ width: '10%' }} className='text-center'>Delivery Location </th>
                                    <th style={{ width: '7%' }} className='text-center'>Rate (Per Unit)</th>
                                    <th style={{ width: '7%' }} className='text-center'>Transportation Cost (Per Unit)</th>
                                    <th style={{ width: '7%' }} className='text-center'>Effective Rate (Rate + Transportation Cost)</th>
                                    <th style={{ width: '7%' }} className='text-center'>Quantity</th>
                                    <th style={{ width: '7%' }} className='text-center'>Amount</th>
                                    <th style={{ width: '7%' }} className='text-center'>SR Max Discount (%)</th>
                                    <th style={{ width: '7%' }} className='text-center'>Admin Max Discount (%)</th>
                                    <th style={{ width: '7%' }} className='text-center'>Discount (%)</th>
                                    <th style={{ width: '7%' }} className='text-center'>After Discount Amount</th>
                                    <th style={{ width: '7%' }}></th>
                                </tr>
                            </thead>

                            <tbody>

                                {orderRows.map((row, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <Select
                                                // value={product.idItems}
                                                // defaultValue={['label': 'ddd', 'value': 0]} 
                                                placeholder={row.productName}
                                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                options={products}
                                                className='sm'
                                                menuPosition="fixed"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                        minHeight: '32px',
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                    }),
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input type="text" name="delivery_location" value={row.delivery_location} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="rate" onChange={(event) => handleChange(event, index)} value={row.rate} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="transportation_cost" value={row.transportation_cost} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="effective_rate" readOnly value={row.effective_rate} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="quantity" value={row.quantity} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="originalAmount" readOnly value={row.originalAmount} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="srDiscount" value={row.srDiscount} className="form-control form-control-sm" readOnly />
                                        </td>
                                        <td>
                                            <input type="number" name="adminDiscount" value={row.adminDiscount} className="form-control form-control-sm" readOnly />
                                        </td>
                                        <td>
                                            <input type="number" name="discount" value={row.discount} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="amount" readOnly value={row.amount} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteProduct(index)}>
                                                <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                            </button>
                                            {index === orderRows.length - 1 && (
                                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addProduct(index)}>
                                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td colSpan={11} className='text-end'><b>Total Order Amount:</b></td>
                                    <td><b>{numberFormat(calculateTotalAmount())}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={11} className='text-end'><b>Total Discount Amount:</b></td>
                                    <td><b>{numberFormat(calculateTotalDiscountAmount())}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={11} className='text-end'><b>Net Amount:</b></td>
                                    <td><b>{numberFormat(calculateTotalAmount() - calculateTotalDiscountAmount())}</b></td>
                                </tr>
                                {company &&
                                    <>
                                        {/* <tr>
                                            <td colSpan={11} className='text-end'><b>Maximum Credit Limit for Client:</b></td>
                                            <td><b>{numberFormat(company.maximumCreditLimit)}</b></td>
                                        </tr> */}

                                        <tr>
                                            <td colSpan={11} className='text-end'><b>Due Amount for this Client:</b></td>
                                            <td>
                                                <span style={{ color: company.dueColor }}><b>{numberFormat(company.dueAmount)}</b></span>
                                            </td>
                                        </tr>

                                        {/* <tr>
                                            <td colSpan={11} className='text-end'><b>Available Credit:</b></td>
                                            <td>
                                                <b>
                                                    <span className={`text-${company.availableCredit >= calculateTotalAmount() ? 'success' : 'danger'}`}>
                                                        {numberFormat(company.availableCredit)}
                                                    </span>
                                                </b>
                                            </td>
                                        </tr> */}
                                    </>
                                }

                            </tbody>
                        </Table>

                        <br></br>
                        <h4>Business Terms & Conditions:</h4>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '75%' }}>Clause</th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                            </thead>

                            <tbody>

                                {orderTerms.map((row, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <input type="text" name="clause" value={row.clause} onChange={(event) => handleTermChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteTerm(index)}>
                                                <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                            </button>
                                            {index === orderTerms.length - 1 && (
                                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addTerm(index)}>
                                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add

                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>



                        </Table>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <input type="submit" className="btn btn-sm btn-success me-2" disabled={company.transactionPossible} />
                                <Link to='/sales-orders' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>

    )
}

export default SalesOrderCreate
