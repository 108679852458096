/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory, useLocation } from "react-router-dom";


interface InvoiceRows {
    idSalesOrderRows: number;
    productName: string;
    orderedQuantity: number;
    deliveredQuantity: number;
    rate: number;
    amount: number;
    [key: string]: any; // Index signature
}

interface InvoiceData {
    idSalesOrders: number | null;
    idProjects: number;
    idShipments: any | null;
    idSalesOrderReturns: any | null;
    date: string;
    vatAmount: number | null;
    discountAmount: number | null;
    invoiceId: string;
    advanceAdjustment: number | null;
}


const SalesInvoiceCreate = () => {

    let history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idSalesOrder = queryParams.get('idSalesOrder');
    let idProjects = queryParams.get('idProjects');
    const projectName = queryParams.get('projectName');

    const invoiceData: InvoiceData = {
        idSalesOrders: null,
        idProjects: 0,
        idShipments: null,
        idSalesOrderReturns: null,
        date: '',
        vatAmount: 0,
        discountAmount: 0,
        invoiceId: '',
        advanceAdjustment: 0
    };

    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    const [maxAdjustableAmount, setMaxAdjustableAmount] = useState(0);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({ label: 'Select Order', value: '' });
    const [shipments, setShipments] = useState([]);
    const [shipment, setShipment] = useState([]);
    const [salesOrderReturns, setSalesOrderReturns] = useState([]);
    const [salesOrderReturn, setSalesOrderReturn] = useState([]);
    const [invoice, setInvoice] = useState(invoiceData);

    useEffect(() => {

        if (idSalesOrder !== null) {
            let salesOrder = { label: 'Order # ' + idSalesOrder, value: idSalesOrder };
            setOrder(salesOrder)
            // setInvoice({ ...invoice, idSalesOrders: parseInt(idSalesOrder) });

            // console.log(invoice)
            if (idProjects && projectName) {
                setInvoice({ ...invoice, idProjects: parseInt(idProjects), idSalesOrders: parseInt(idSalesOrder) });
                let projectTmp = { label: projectName, value: idProjects };
                setProject(projectTmp)
            }
            getOrderShipments(parseInt(idSalesOrder));

        } else {
            getProjects();
        }
    }, [])

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjectOrders = (idProjects: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'sales-order-by-project/' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let orderObj = resp.data.map((item: any) => {
                    item.label = 'Order # ' + item.id + ', Date: ' + item.sales_order_date + ', Client: ' + item.company_name + ', Amount: ' + item.order_amount;
                    item.value = item.id;
                    return item
                })
                setOrders(orderObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getOrderShipments = (idOrders: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'shipments-by-order/' + idOrders,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let shipmentsObj = resp.data.map((item: any) => {
                    item.label = 'Shipment # ' + item.id;
                    item.value = item.id;
                    return item
                })

                setShipments(shipmentsObj);

                let salesOrderReturnsObj = resp.salesOrderReturns.map((item: any) => {
                    item.label = 'SalesOrderReturn # ' + item.id;
                    item.value = item.id;
                    return item
                })

                setSalesOrderReturns(salesOrderReturnsObj);

                let invoiceRows = [...resp.rows];

                invoiceRows = invoiceRows.map((row, index) => ({
                    ...row,
                    idSalesOrderRows: parseFloat(row.idSalesOrderRows),
                    orderedQuantity: parseFloat(row.orderedQuantity),
                    deliveredQuantity: 0,
                    rate: parseFloat(row.rate),
                    productName: row.productName,
                    amount: 0,
                }));

                setInvoiceRows(invoiceRows);
                setMaxAdjustableAmount(resp.maxAdjustableAmount)

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setOrder({ label: 'Select Order', value: '' })
        setShipment([])
        setInvoiceRows([])
        setProject(project);
        setMaxAdjustableAmount(0)
        getProjectOrders(project.value);
        setInvoice({ ...invoice, idProjects: project.value });
    }

    const handleOrderChange = (order: any) => {
        setOrder(order);
        getOrderShipments(order.value);
        setShipment([]);
        setInvoice({ ...invoice, idSalesOrders: order.value });
    }

    const handleShipmentChange = (shipment: any) => {
        setShipment(shipment);
        setInvoice({ ...invoice, idShipments: shipment });
        getOrderData(shipment, salesOrderReturn);
    }

    const handleSalesOrderReturnChange = (salesOrderReturn: any) => {
        setSalesOrderReturn(salesOrderReturn);
        setInvoice({ ...invoice, idSalesOrderReturns: salesOrderReturn });
        getOrderData(shipment, salesOrderReturn);
    }

    const getOrderData = async (shipment: any, salesOrderReturn: any) => {

        const postData = {
            idSalesOrders: invoice.idSalesOrders,
            shipment: shipment,
            salesOrderReturn: salesOrderReturn,
        }
        const requestOptions = postRequestOptions(postData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}sales-order-data`,
                requestOptions
            )
            let resp = await response.json()

            let invoiceRows = [...resp.data];

            invoiceRows = invoiceRows.map((row, index) => ({
                ...row,
                amount: (parseFloat(row.rate) * parseFloat(row.deliveredQuantity)),
            }));

            setInvoiceRows(invoiceRows);

        } catch (error) {
            console.log(error, 'catch the hoop')
            // setSubmitDisable(false);
            // setLoading(false);
        }


    }

    // const [isSubmitDisable, setSubmitDisable] = useState(false);
    // const [loading, setLoading] = useState(false);

    const invoiceSave = async (event: any) => {
        event.preventDefault()
        // setSubmitDisable(true);
        // setLoading(true);
        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this Invoice?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Create it!',
        })
        if (confirm.value) {
            const postData = {
                invoice: invoice,
                invoiceRows: invoiceRows,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}sales-invoice`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    history.push("/sales-invoice/" + respData.idSalesInvoice);
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
                // setSubmitDisable(false);
                // setLoading(false);
            } catch (error) {
                console.log(error, 'catch the hoop')
                // setSubmitDisable(false);
                // setLoading(false);
            }
        }
    }



    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.name === 'advanceAdjustment') {
            if (parseFloat(event.target.value) > maxAdjustableAmount) {
                event.target.value = ''
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Max Adjustable Amount Exceed!',
                })
                return
            }
        }
        setInvoice({ ...invoice, [event.target.name]: event.target.value });
    }

    const calculateTotalAmount = () => {
        let totalAmount = 0;

        for (const row of invoiceRows) {
            totalAmount += row.amount;
        }

        return totalAmount;
    };

    const [invoiceRows, setInvoiceRows] = useState<InvoiceRows[]>([]);

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Create Sales Invoice
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">
                    {/* <pre>{JSON.stringify(invoice, null, 2)}</pre> */}
                    <form onSubmit={invoiceSave}>

                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idSalesOrders" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Order <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={order}
                                    onChange={handleOrderChange}
                                    options={orders}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idShipments" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Shipments <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={shipment}
                                    onChange={handleShipmentChange}
                                    isMulti
                                    options={shipments}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idShipments" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Tag Sales Order Return </label>
                            <div className="col-lg-5">
                                <Select
                                    value={salesOrderReturn}
                                    onChange={handleSalesOrderReturnChange}
                                    isMulti
                                    options={salesOrderReturns}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="date" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Invoice Date  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="date" id="date" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        {/* <div className="form-group row mb-2">
                            <label htmlFor="invoiceId" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Invoice ID <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input name="invoiceId" id="invoiceId" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div> */}

                        <br></br>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '25%' }}>Product Name</th>
                                    <th style={{ width: '10%' }}>Unit</th>
                                    <th style={{ width: '10%' }}>Rate</th>
                                    <th style={{ width: '10%' }}>Ordered Quantity</th>
                                    <th style={{ width: '10%' }}>Delivered Quantity</th>
                                    <th style={{ width: '10%' }}>Amount</th>
                                </tr>
                            </thead>


                            <tbody>

                                {invoiceRows.map((row, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>{row.productName + ' (Size: ' + row.packing +' '+row.item_unit_name+ ')'}</td>
                                        <td>{row.product_unit_name}</td>
                                        <td>{numberFormat(row.rate)}</td>
                                        <td>{row.orderedQuantity}</td>
                                        <td>{row.deliveredQuantity}</td>
                                        <td>{numberFormat(row.amount)}</td>
                                    </tr>
                                ))}

                                <tr>
                                    <td colSpan={6} className='text-end'><b>Total Invoice Amount:</b></td>
                                    <td><b>{numberFormat(calculateTotalAmount())}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className='text-end'><b>VAT Amount:</b></td>
                                    <td><input type='number' step="any" name="vatAmount" id="vatAmount" onChange={handleInputOnChange} className="form-control form-control-sm" /></td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className='text-end'><b>Discount Amount:</b></td>
                                    <td><input type='number' step="any" name="discountAmount" id="discountAmount" onChange={handleInputOnChange} className="form-control form-control-sm" /></td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className='text-end'><b>Adjustment Amount:</b></td>
                                    <td>
                                        <input type='number' step="any" name="advanceAdjustment" id="advanceAdjustment" onChange={handleInputOnChange} className="form-control form-control-sm" />
                                        Max Adjustable Amount : {numberFormat(maxAdjustableAmount)}
                                    </td>
                                </tr>

                            </tbody>



                        </Table>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                {/* <pre>{JSON.stringify(invoice, null, 2)}</pre> */}
                                <input type="submit" className="btn btn-sm btn-success me-2" />
                                <Link to='/sales-invoices' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>

    )
}

export default SalesInvoiceCreate
