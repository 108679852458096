/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory } from "react-router-dom";

interface QuotationRows {
    idProducts: number;
    idProductGroupAlternateNames: number;
    quantity: number;
    rate: number;
    transportation_cost: number;
    effective_rate: number;
    discount: number;
    amount: number;
    originalRate: number;
    remarks: string;
    delivery_location: string;
    [key: string]: any; // Index signature
}

interface QuotationTerms {
    clause: string;
    [key: string]: any;
}

interface Product {
    id_products: number;
    product_name: string;
}

interface QuotationData {
    idProjects: number;
    idLeads: number;
    date: string;
    referenceNo: string;
    quotationBody: string;
    quotationFooter: string;
}


const SalesQuotationCreate = () => {

    let history = useHistory();

    const initTextPre = ''
    const [initTextFooter, setInitTextFooter] = useState('');

    const getUserInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'users/get_user_info',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {


            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const [initText, setInitText] = useState(initTextPre);

    const quotationData: QuotationData = {
        idProjects: 0,
        idLeads: 0,
        date: '',
        referenceNo: '',
        quotationBody: initText,
        quotationFooter: initTextFooter
    };

    const handleQuotationBodyChange = (content: any) => {
        setQuotation({ ...quotation, quotationBody: content });
    }

    const handleQuotationFooterChange = (content: any) => {
        setQuotation({ ...quotation, quotationFooter: content });
    }

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [leads, setLeads] = useState([]);
    const [quotation, setQuotation] = useState(quotationData);
    const [lead, setLead] = useState({ label: 'Select Lead', value: '' });
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        getProjects();
        // getProducts();
        getUserInfo();
    }, [])

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjectLeads = (idProjects: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'getLeadsByProject/' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let leadObj = resp.data.map((item: any) => {
                    item.label = item.lead_name;
                    item.value = item.id_leads;
                    return item
                })
                setLeads(leadObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getInitText = (idLeads: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'quotationInitText/' + idLeads,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                console.log(resp);
                setInitText(resp.text)
                setInitTextFooter(resp.textFooter)
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        getProjectLeads(project.value);
        setQuotation({ ...quotation, idProjects: project.value });
        getProducts(project.value);
        setQuotationRows([{
            idProducts: 0,
            idProductGroupAlternateNames: 0,
            quantity: 0,
            rate: 0,
            transportation_cost: 0,
            effective_rate: 0,
            discount: 0,
            amount: 0,
            originalRate: 0,
            remarks: '',
            delivery_location: '',
        }]);
    }

    const handleLeadChange = (lead: any) => {
        setLead(lead);
        setQuotation({ ...quotation, idLeads: lead.value });
        getInitText(lead.value);
    }

    const handleItemChange = (data: any, index: number) => {
        const updatedQuotationRows = [...quotationRows];
        updatedQuotationRows[index].idProducts = data.value.split('-')[0];
        updatedQuotationRows[index].idProductGroupAlternateNames = data.value.split('-')[1] === "null" ? null : data.value.split('-')[1];
        updatedQuotationRows[index].originalRate = parseFloat(data.sale_per_unit);
        updatedQuotationRows[index].effective_rate = parseFloat(data.sale_per_unit);
        updatedQuotationRows[index].rate = parseFloat(data.sale_per_unit);
        setQuotationRows(updatedQuotationRows);
    }

    const getProducts = (project: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'products?idProjects=' + project,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let products = resp.data.map((item: any) => {
                    // item.label = item.product_name + ' (' + item.packing + ')';
                    // item.value = item.id_products;
                    item.label = item.name_value + ' (' + item.packing + ' ' + item.item_unit_name + ')';
                    item.value = item.id_products + '-' + item.id_product_group_alternate_names;
                    item.unitRate = item.sale_per_unit;
                    return item
                })
                setProducts(products);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }


    // const [isSubmitDisable, setSubmitDisable] = useState(false);
    // const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedQuotationRows = [...quotationRows];
        updatedQuotationRows[index][e.target.name] = (e.target.type === 'number') ? parseFloat(e.target.value) : e.target.value;
        updatedQuotationRows[index].rate = updatedQuotationRows[index].originalRate - (updatedQuotationRows[index].discount * updatedQuotationRows[index].originalRate / 100);
        updatedQuotationRows[index].effective_rate = updatedQuotationRows[index].rate + updatedQuotationRows[index].transportation_cost;
        updatedQuotationRows[index].amount = parseFloat((updatedQuotationRows[index].quantity * (updatedQuotationRows[index].rate + updatedQuotationRows[index].transportation_cost)).toFixed())
        setQuotationRows(updatedQuotationRows);
    }

    const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedQuotationTerms = [...quotationTerms];
        updatedQuotationTerms[index][e.target.name] = e.target.value;
        setQuotationTerms(updatedQuotationTerms);
    }

    const deleteProduct = (index: number) => {
        const updatedQuotationRows = [...quotationRows];
        if (updatedQuotationRows && updatedQuotationRows[index]) {
            updatedQuotationRows.splice(index, 1);
            setQuotationRows(updatedQuotationRows);
        }
    }

    const deleteTerm = (index: number) => {
        const updatedQuotationTerms = [...quotationTerms];
        if (updatedQuotationTerms && updatedQuotationTerms[index]) {
            updatedQuotationTerms.splice(index, 1);
            setQuotationTerms(updatedQuotationTerms);
        }
    }

    const quotationSave = async (event: any) => {
        event.preventDefault()
        // setSubmitDisable(true);
        // setLoading(true);
        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this Quotation?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Create it!',
        })
        if (confirm.value) {
            const postData = {
                quotation: quotation,
                quotationRows: quotationRows,
                quotationTerms: quotationTerms,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}quotation`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    history.push("/sales-quotations");
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
                // setSubmitDisable(false);
                // setLoading(false);
            } catch (error) {
                console.log(error, 'catch the hoop')
                // setSubmitDisable(false);
                // setLoading(false);
            }
        }
    }

    const addProduct = (index: number) => {
        const newRow: QuotationRows = {
            idProducts: 0,
            idProductGroupAlternateNames: 0,
            quantity: 0,
            rate: 0,
            transportation_cost: 0,
            effective_rate: 0,
            discount: 0,
            amount: 0,
            originalRate: 0,
            remarks: '',
            delivery_location: '',
        };

        const updatedRows = [...quotationRows];
        updatedRows.push(newRow);

        setQuotationRows(updatedRows);
    }

    const addTerm = (index: number) => {
        const newTerm: QuotationTerms = {
            clause: '',
        };

        const updatedTerms = [...quotationTerms];
        updatedTerms.push(newTerm);
        setQuotationTerms(updatedTerms);
    }

    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuotation({ ...quotation, [event.target.name]: event.target.value });
    }

    const editorConfig = {
        height: 500,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'textalignjustify',
        ],
        toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help'
    };

    const [quotationRows, setQuotationRows] = useState<QuotationRows[]>([{
        idProducts: 0,
        idProductGroupAlternateNames: 0,
        quantity: 0,
        rate: 0,
        transportation_cost: 0,
        effective_rate: 0,
        discount: 0,
        amount: 0,
        originalRate: 0,
        remarks: '',
        delivery_location: '',
    }]);

    const [quotationTerms, setQuotationTerms] = useState<QuotationTerms[]>([{
        clause: '',
    }]);

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Create Sales Quotation
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">
                    {/* {<pre>{JSON.stringify(quotationRows, null, 2)}</pre>} */}
                    <form onSubmit={quotationSave}>
                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Leads <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={lead}
                                    onChange={handleLeadChange}
                                    options={leads}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="date" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Date  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="date" id="date" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        {/* <div className="form-group row mb-2">
                            <label htmlFor="referenceNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Reference No  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input name="referenceNo" id="referenceNo" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div> */}
                        <div className="form-group row mb-2">
                            <label htmlFor="quotationBody" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Quotation Header  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Editor
                                    apiKey="c86hkmb8gwenrnbdfmj540pulih521ihze9v44lv1sf9a80h"
                                    initialValue={initText}
                                    init={editorConfig}
                                    onEditorChange={handleQuotationBodyChange}
                                />
                            </div>
                        </div>

                        <br></br>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '16%' }} className='text-center'>Product Name <span className="text-danger">*</span></th>
                                    <th style={{ width: '8%' }} className='text-center'>Delivery Location</th>
                                    <th style={{ width: '8%' }} className='text-center'>Rate (Per Unit)</th>
                                    <th style={{ width: '8%' }} className='text-center'>Transportation Cost (Per Unit)</th>
                                    <th style={{ width: '8%' }} className='text-center'>Effective Rate (Rate + Transportation Cost)</th>
                                    <th style={{ width: '8%' }} className='text-center'>Quantity</th>
                                    <th style={{ width: '8%' }} className='text-center'>Amount</th>
                                    <th style={{ width: '8%' }} className='text-center'>Discount (%)</th>
                                    <th style={{ width: '8%' }} className='text-center'>Remarks</th>
                                    <th style={{ width: '15%' }}></th>
                                </tr>
                            </thead>


                            <tbody>

                                {quotationRows.map((row, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <Select
                                                // value={product.idItems}
                                                // placeholder={row.productName}
                                                // defaultValue={{ value: null, label: 'Select...', product_name: null, id_products: null }}
                                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                options={products}
                                                className='sm'
                                                menuPosition="fixed"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                        minHeight: '32px',
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                    }),
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input type="text" name="delivery_location" value={row.delivery_location} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="rate" readOnly value={row.rate} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="transportation_cost" value={row.transportation_cost} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="effective_rate" readOnly value={row.effective_rate} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="quantity" onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="amount" readOnly value={row.amount} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="discount" onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="text" name="remarks" onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteProduct(index)}>
                                                <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                            </button>
                                            {index === quotationRows.length - 1 && (
                                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addProduct(index)}>
                                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add More Product
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>



                        </Table>
                        <br></br>
                        <h4>Business Terms & Conditions:</h4>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '75%' }}>Clause</th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                            </thead>

                            <tbody>

                                {quotationTerms.map((row, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <input type="text" name="clause" value={row.clause} onChange={(event) => handleTermChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteTerm(index)}>
                                                <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                            </button>
                                            {index === quotationTerms.length - 1 && (
                                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addTerm(index)}>
                                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add More Term
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>



                        </Table>

                        <div className="form-group row mb-2">
                            <label htmlFor="quotationBody" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Quotation Footer  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Editor
                                    apiKey="c86hkmb8gwenrnbdfmj540pulih521ihze9v44lv1sf9a80h"
                                    initialValue={initTextFooter}
                                    init={editorConfig}
                                    onEditorChange={handleQuotationFooterChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <input type="submit" className="btn btn-sm btn-success me-2" />
                                <Link to='/sales-quotations' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>

    )
}

export default SalesQuotationCreate
